interface ApplicationConfig {
  readonly backendApiBaseUrl: string
  readonly frontendBaseUrl: string
  readonly trackingToken: string
}

export const applicationConfig: ApplicationConfig = {
  backendApiBaseUrl:
    process.env.REACT_APP_BACKEND_API_BASE_URL || "http://localhost:8080",
  frontendBaseUrl:
    process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000",
  trackingToken: process.env.REACT_APP_MIXPANEL_TOKEN || "",
}
