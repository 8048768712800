interface SessionStorageRepository<T> {
  readonly save: (thing: T) => void
  readonly get: () => T | null
  readonly clear: () => void
}

export function sessionStorageRepository<T>(
  key: string,
): SessionStorageRepository<T> {
  return {
    save: (object) => sessionStorage.setItem(key, JSON.stringify(object)),
    get: () => {
      try {
        const item = sessionStorage.getItem(key)
        return item ? JSON.parse(item) : null
      } catch {
        return null
      }
    },
    clear: () => sessionStorage.removeItem(key),
  }
}
