import * as React from "react"
import { ReactElement } from "react"
import { Outlet } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import { marketingTheme } from "../../theme"
import { Header } from "./header/Header"
import { Footer } from "../common/Footer"
import { Main } from "../common/Main"

export function AppMarketing(): ReactElement {
  return (
    <ThemeProvider theme={marketingTheme}>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </ThemeProvider>
  )
}
