import { ReactElement } from "react"
import { Stack, Typography } from "@mui/material"
import Wellbeing from "./hope-therapy-wellbeing.png"
import { styled } from "@mui/material/styles"
import { TickList } from "../../../component/list/TickList"
import { MarketingPage } from "../MarketingPage"
import { LinkPrimaryButton } from "../../../component/buttons/LinkButton"
import { isMatchingFlowEnabled } from "../../../toggles"
import { PersonSearch } from "@mui/icons-material"

const Image = styled("img")(() => ({
  width: "100%",
  maxWidth: "588px", // The actual size of the image
  borderRadius: "2rem",
}))

export function HomePageClient(): ReactElement {
  return (
    <MarketingPage
      title={"You and your well-being matter"}
      left={
        <Stack direction={"column"} spacing={2}>
          <Typography variant={"h2"}>
            Find and book a therapist in 3 easy steps.
          </Typography>
          <TickList
            items={[
              "Search therapists",
              "Find a time that works for you",
              "Book and pay for your session",
            ]}
          />
          {isMatchingFlowEnabled() && (
            <LinkPrimaryButton
              trackingName={"Client homepage go to matching click"}
              to={"/matching"}
              sx={(theme) => ({
                width: "fit-content",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              startIcon={<PersonSearch />}
            >
              Find a therapist
            </LinkPrimaryButton>
          )}
          {!isMatchingFlowEnabled() && (
            <LinkPrimaryButton
              trackingName={"Client homepage go to search click"}
              to={"/search/page/1"}
              sx={(theme) => ({
                width: "10rem",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              Find a therapist
            </LinkPrimaryButton>
          )}
        </Stack>
      }
      right={<Image src={Wellbeing} alt={""} />}
    />
  )
}
