import { createBrowserRouter, RouteObject } from "react-router-dom"
import { AppRoot } from "./AppRoot"
import { RedirectPage } from "./pages/common/RedirectPage"
import { AppMarketing } from "./pages/marketing/AppMarketing"
import { clientRoutes } from "./pages/client/routesClient"
import { therapistRoutes } from "./pages/therapist/routesTherapist"
import { marketingRoutes } from "./pages/marketing/routesMarketing"
import { UnexpectedErrorPage } from "./pages/common/errors/UnexpectedErrorPage"

// Monitor these routes in analytics and if no one tries to use them for a
// while we can delete
const deprecatedOldRoutes: RouteObject[] = [
  {
    path: "/therapist/profile",
    element: <RedirectPage to={"/my/dashboard"} />,
  },
  {
    path: "/therapist/onboarding",
    element: <RedirectPage to={"/my/signup"} />,
  },
  {
    path: "/therapist/terms-acceptance",
    element: <RedirectPage to={"/my/terms-acceptance"} />,
  },
  {
    path: "/search",
    element: <RedirectPage to={"/search/page/1"} />,
  },
]

const routes: RouteObject[] = [
  {
    element: <AppRoot />,
    children: [
      {
        errorElement: <UnexpectedErrorPage />,
        children: [
          {
            lazy: () => import("./pages/therapist/AppTherapist"),
            children: therapistRoutes,
          },
          {
            lazy: () => import("./pages/client/AppClient"),
            children: clientRoutes,
          },
          { element: <AppMarketing />, children: marketingRoutes },
          ...deprecatedOldRoutes,
          {
            path: "*",
            lazy: () => import("./pages/common/errors/NotFoundPage"),
          },
        ],
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
