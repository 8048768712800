import { useEffect } from "react"

export function useUpdateBrowserTitle(title: string): void {
  useEffect(() => {
    document.title = `${title} - Hope`
    return () => {
      document.title = "Hope"
    }
  }, [title])
}
