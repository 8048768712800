import { styled } from "@mui/material/styles"
import { hopePink } from "../../theme"
import { PrimaryButton } from "./PrimaryButton"

export const SecondaryButton = styled(PrimaryButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: "white",
  color: theme.palette.primary.main,
  ":hover": {
    backgroundColor: hopePink[50],
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}))
