import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import { HomeOutlined, SearchOutlined } from "@mui/icons-material"
import { ReactElement } from "react"
import { NavLink } from "react-router-dom"

interface ClientMenuProps {
  readonly anchorEl: HTMLElement | null
  readonly setAnchorEl: (anchorEl: HTMLElement | null) => void
}

export function ClientMenuButton({
  anchorEl,
  setAnchorEl,
}: ClientMenuProps): ReactElement {
  return (
    <Button
      id={"client-menu"}
      aria-controls={anchorEl ? "client-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={anchorEl ? "true" : undefined}
      onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
    >
      Looking for therapy
    </Button>
  )
}

export function ClientMenu({
  anchorEl,
  setAnchorEl,
}: ClientMenuProps): ReactElement {
  const onClose = () => setAnchorEl(null)

  return (
    <Menu
      id={"client-menu"}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "client-menu",
      }}
    >
      <MenuItem component={NavLink} to={"/"} onClick={onClose}>
        <ListItemIcon>
          <HomeOutlined />
        </ListItemIcon>
        <ListItemText>Home</ListItemText>
      </MenuItem>
      <MenuItem component={NavLink} to={"/search/page/1"} onClick={onClose}>
        <ListItemIcon>
          <SearchOutlined />
        </ListItemIcon>
        <ListItemText>Find a therapist</ListItemText>
      </MenuItem>
    </Menu>
  )
}
