import { createTheme, Theme } from "@mui/material"

// Colours generated using https://m2.material.io/inline-tools/color/

/** 900 is the main shade */
export const hopeBlue = {
  50: "#e5e6ee",
  100: "#bdc0d5",
  200: "#9398b8",
  300: "#6b729c",
  400: "#4f5589",
  500: "#333a77",
  600: "#2e336f",
  700: "#262b64",
  800: "#1f2258",
  900: "#131342",
}

/** 300 is the main shade */
export const hopePink = {
  50: "#ffeef1",
  100: "#ffd4d9",
  200: "#ffa5a5",
  300: "#ff8181",
  400: "#ff635e",
  500: "#ff5343",
  600: "#ff4c44",
  700: "#f3423d",
  800: "#e63b36",
  900: "#d7302a",
}

/** 200 is the main shade */
export const hopeGreen = {
  50: "#dfeced",
  100: "#b0d2d2",
  200: "#7fb5b5",
  300: "#4e9998",
  400: "#288684",
  500: "#017370",
  600: "#036764",
  700: "#045955",
  800: "#044a46",
  900: "#02302b",
}

let root: Theme = createTheme({
  palette: {
    primary: {
      main: hopeBlue[900],
    },
    secondary: {
      main: hopePink[300],
    },
  },
  // https://www.modularscale.com/?1&rem&1.25
  typography: {
    h1: {
      fontSize: "1.953rem",
    },
    h2: {
      fontSize: "1.563rem",
    },
    h3: {
      fontSize: "1.25rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      lineHeight: "1",
    },
    fontFamily: ["LatoRegular", "LatoBlack", "sans-serif"].join(","),
  },
})

root = createTheme(root, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                  font-family: 'Lato';
                }`,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: root.palette.primary.main,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: root.palette.secondary.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
})

let marketing: Theme = createTheme(root, {
  // https://www.modularscale.com/?1&rem&1.25
  typography: {
    h1: {
      fontSize: "3.815rem",
    },
    h2: {
      fontSize: "3.052rem",
    },
    h3: {
      fontSize: "2.441rem",
    },
    h4: {
      fontSize: "1.953rem",
    },
    h5: {
      fontSize: "1.563rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
  },
})

marketing = createTheme(marketing, {
  typography: {
    h1: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "2.441rem",
      },
    },
    h2: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "1.953rem",
      },
    },
    h3: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "1.563rem",
      },
    },
    h4: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    h5: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    h6: {
      [marketing.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  },
})

export const rootTheme: Theme = root
export const marketingTheme: Theme = marketing
