import { ReactElement } from "react"
import { Page } from "../Page"
import { UnexpectedErrorMessage } from "./UnexpectedErrorMessage"
import { useRouteError } from "react-router-dom"

export function UnexpectedErrorPage(): ReactElement {
  const error = useRouteError()
  return (
    <Page title={"Error"}>
      <UnexpectedErrorMessage error={error} />
    </Page>
  )
}
