import React, { ReactElement, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import {
  hasEssentialCookiesConsentCookie,
  setAnalyticsCookie,
  setEssentialCookie,
} from "./CookieUtils"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { PrimaryButton } from "../../../component/buttons/PrimaryButton"
import { SecondaryButton } from "../../../component/buttons/SecondaryButton"
import { InternalLink } from "../../../component/links/Links"

interface ManageDialogProps {
  readonly open: boolean
  readonly onClose: () => void
  readonly onAccept: (analytics: boolean) => void
}

function ManageDialog({
  open,
  onClose,
  onAccept,
}: ManageDialogProps): ReactElement {
  const [analytics, setAnalytics] = useState(false)
  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown>
      <DialogTitle>Cookies consent</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Essential</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ marginBottom: "1rem" }}>
                  We use cookies in order to determine if you are logged in or
                  not. When you login with Google, Google will set a cookie that
                  lets them know you are authenticated to Hope and that we are
                  able to process your information from them in the ways
                  specified in the consent screen.
                </Typography>
                <Typography sx={{ marginBottom: "1rem" }}>
                  For example, if you're a therapist, you need to give Hope
                  permission to access your Google calendar and to send emails
                  via Gmail as you. This is so that we can allow clients to see
                  your calendar and send reminder emails when necessary.
                </Typography>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Hope also sets its own cookies and local storage items that
                  allow us to see if you are authenticated to Hope. This allows
                  us to display to you the correct information for your user
                  such as booking information as a client and profile editing as
                  a therapist. For more detailed information please visit our{" "}
                  <InternalLink to={"/privacy-policy"}>
                    privacy policy page.
                  </InternalLink>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={2}>
            <Switch defaultChecked disabled />
          </Grid>
          <Grid item xs={10}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Analytics</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ marginBottom: "1rem" }}>
                  To allow us to build the right features for our users and
                  prioritise their needs we use analytics. We do this using{" "}
                  <a href={"https://eu.mixpanel.com/"}>Mixpanel</a>. This allows
                  us to see which pages, buttons and actions each person on our
                  site is visiting/clicking/performing most. What this means for
                  us is that we can see where our users are focusing and where
                  they are maybe having issues etc. with usability meaning we
                  can prioritise fixing these issues or evolving the most used
                  features giving our users the best experience possible.
                </Typography>
                <Typography sx={{ marginBottom: "1rem" }}>
                  We respect your privacy and will never track any personally
                  identifiable information (PII), the only data we receive about
                  actions performed by users is related to the action or event
                  they are performing. For example, if you accept analytics and
                  are booking a therapy session, when you select options for
                  your session and then select an available timeslot we know
                  that what options you chose before selecting that timeslot.
                  What this enables us to do is understand if the default
                  options provided are the best or if they could be changed to
                  save our users time and effort. We may at some points capture
                  information you enter allowing us to tailor needs to types of
                  users we would not be able to relate that information directly
                  to individuals. You can read more about this on our{" "}
                  <InternalLink to={"/privacy-policy"}>
                    privacy policy page.
                  </InternalLink>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={2}>
            <Switch
              checked={analytics}
              onChange={(e) => setAnalytics(e.target.checked)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          trackingName={"Cancel manage cookies"}
          onClick={onClose}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          trackingName={"Manage cookies accept selected cookies"}
          onClick={() => onAccept(analytics)}
        >
          Accept selected
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export function CookiesBanner(): ReactElement {
  const [openManageDialog, setOpenManageDialog] = useState(false)
  const [showCookiesConsent, setShowCookiesConsent] = useState(
    !hasEssentialCookiesConsentCookie(),
  )

  const acceptAll = (): void => {
    setEssentialCookie(true)
    setAnalyticsCookie(true)
    setShowCookiesConsent(false)
  }

  const acceptSelected = (analytics: boolean): void => {
    setEssentialCookie(true)
    setAnalyticsCookie(analytics)
    setShowCookiesConsent(false)
    onCloseDialog()
  }

  const onCloseDialog = (): void => {
    setOpenManageDialog(false)
  }

  return (
    <Drawer open={showCookiesConsent} anchor={"bottom"} disableEscapeKeyDown>
      <ManageDialog
        open={openManageDialog}
        onClose={onCloseDialog}
        onAccept={acceptSelected}
      />
      <DialogTitle>Cookies consent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          In order to allow the site to perform to its optimum we require your
          permission to use some necessary cookies. If you would like to
          customise which cookies we store we understand please click 'manage'.
          If you're happy for us to store cookies which help us understand user
          behaviour and trends, go ahead and click 'accept all' to continue.
        </DialogContentText>
        <DialogActions>
          <SecondaryButton
            trackingName={"UNUSED"}
            onClick={() => setOpenManageDialog(true)}
          >
            Manage
          </SecondaryButton>
          <PrimaryButton
            trackingName={"Accept all cookies"}
            onClick={acceptAll}
          >
            Accept all
          </PrimaryButton>
        </DialogActions>
      </DialogContent>
    </Drawer>
  )
}

export default CookiesBanner
