import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { applicationConfig } from "../ApplicationConfig"

export interface ApiErrorType {
  readonly error: string
  readonly status?: number
}

interface ApiErrorResponse {
  readonly message: string
}

const instance = axios.create({
  baseURL: applicationConfig.backendApiBaseUrl,
  withCredentials: true,
})

instance.interceptors.response.use(
  function (response) {
    if (response.status === 204) {
      // Return null instead of empty string for No Content responses
      response.data = null
    }
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      window.location.href =
        "/login?error=Login%20session%20timed%20out%20please%20login%20again"
    }
    return Promise.reject(error)
  },
)

export async function get<Response>(
  url: string,
  additionalConfig: Omit<AxiosRequestConfig, "withCredentials"> = {},
): Promise<Response> {
  const response = await instance.get<Response>(url, {
    ...additionalConfig,
  })
  return response.data
}

export async function put<Response>(
  url: string,
  body?: unknown,
  additionalConfig: Omit<AxiosRequestConfig, "withCredentials"> = {},
): Promise<Response> {
  const response = await instance.put<Response>(url, body, {
    ...additionalConfig,
  })
  return response.data
}

export async function post<Response>(
  url: string,
  body?: unknown,
  additionalConfig: Omit<AxiosRequestConfig, "withCredentials"> = {},
): Promise<Response> {
  const response = await instance.post<Response>(url, body, {
    ...additionalConfig,
  })
  return response.data
}

export async function deleteReq<Response>(
  url: string,
  additionalConfig: Omit<AxiosRequestConfig, "withCredentials"> = {},
): Promise<Response> {
  const response = await instance.delete<Response>(url, {
    ...additionalConfig,
  })
  return response.data
}

/** @deprecated */
export function extractError(
  error: AxiosError<ApiErrorResponse>,
): ApiErrorType {
  return {
    error: error.response?.data?.message ?? error.message,
    status: error.response?.status,
  }
}

export function throwApiError(error: AxiosError<ApiErrorResponse>): never {
  throw new ApiError(
    error.response?.data?.message ?? error.message,
    error.response?.status,
  )
}

export class ApiError extends Error {
  status: number | undefined
  constructor(message: string, status?: number) {
    super(message)
    this.status = status
  }
}
