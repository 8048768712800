import { ReactElement, useState } from "react"
import { TherapistMenu, TherapistMenuButton } from "./TherapistMenu"
import { ClientMenu, ClientMenuButton } from "./ClientMenu"
import { Header as CommonHeader } from "../../common/header/Header"
import { Logo } from "../../common/header/Logo"
import { Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

export function Header(): ReactElement {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [clientMenuEl, setClientMenuEl] = useState<HTMLElement | null>(null)
  const [therapistMenuEl, setTherapistMenuEl] = useState<HTMLElement | null>(
    null,
  )

  return (
    <>
      <CommonHeader>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Logo to={"/"} variant={smallScreen ? "circle" : "full"} />
        </Box>
        <ClientMenuButton
          anchorEl={clientMenuEl}
          setAnchorEl={setClientMenuEl}
        />
        <TherapistMenuButton
          anchorEl={therapistMenuEl}
          setAnchorEl={setTherapistMenuEl}
        />
      </CommonHeader>
      <ClientMenu anchorEl={clientMenuEl} setAnchorEl={setClientMenuEl} />
      <TherapistMenu
        anchorEl={therapistMenuEl}
        setAnchorEl={setTherapistMenuEl}
      />
    </>
  )
}
