import { AppBar, AppBarProps, Container, Toolbar } from "@mui/material"
import { ReactElement, ReactNode } from "react"
import { Breakpoint } from "@mui/system/createTheme/createBreakpoints"

interface HeaderProps extends AppBarProps {
  readonly maxWidth?: Breakpoint | false
  readonly children?: ReactNode
}

export function Header({
  maxWidth,
  children,
  ...props
}: HeaderProps): ReactElement {
  return (
    <AppBar {...props} elevation={0} position={"sticky"} color={"inherit"}>
      <Container maxWidth={maxWidth}>
        <Toolbar disableGutters sx={{ gap: "1rem" }}>
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
