import { RouteObject } from "react-router-dom"
import { HomePageClient } from "./homeClient/HomePageClient"

export const marketingRoutes: RouteObject[] = [
  {
    path: "/",
    element: <HomePageClient />,
  },
  {
    path: "/login",
    lazy: () => import("../therapist/authentication/signin/SigninPage"),
  },
  {
    path: "/oauth2/redirect",
    lazy: () => import("../therapist/authentication/signin/SigninPage"),
  },
  {
    path: "/signup",
    lazy: () => import("../therapist/authentication/signup/SignupPage"),
  },
  {
    path: "/students",
    lazy: () => import("../student/StudentViralLoopsPage"),
  },
  {
    path: "/therapists",
    lazy: () => import("./homeTherapist/HomePageTherapist"),
  },
  {
    path: "/privacy-policy",
    lazy: () => import("./PrivacyPolicyPage"),
  },
  {
    path: "/terms-and-conditions",
    lazy: () => import("./TermsAndConditionsPage"),
  },
  {
    path: "/about",
    lazy: () => import("./about/AboutPage"),
  },
]
