import Cookies from "js-cookie"

export const ESSENTIAL_COOKIE_NAME = "essential"
export const ANALYTICS_COOKIE_NAME = "analytics"

export const setCookie = (name, val) => Cookies.set(name, val)

export const getCookie = (name) => Cookies.get(name)

export const setEssentialCookie = (val) =>
  Cookies.set(ESSENTIAL_COOKIE_NAME, val, { expires: 365 })

export const setAnalyticsCookie = (val) =>
  Cookies.set(ANALYTICS_COOKIE_NAME, val, { expires: 365 })

export const hasEssentialCookiesConsentCookie = () =>
  getCookie(ESSENTIAL_COOKIE_NAME) === "true"

export const hasAnalyticsConsentCookie = () =>
  getCookie(ANALYTICS_COOKIE_NAME) === "true"
