import { ButtonProps } from "@mui/material"
import MixpanelActions from "../../pages/common/analytics/Mixpanel"
import { ReactElement, MouseEvent, ElementType } from "react"
import { Dict } from "mixpanel-browser"
import { LoadingButton } from "@mui/lab"

export interface TrackedButtonProps
  extends ButtonProps<ElementType, { component?: ElementType }> {
  readonly trackingName: string
  readonly trackingDetails?: Dict
  readonly isLoading?: boolean
}

export function TrackedButton({
  trackingName,
  trackingDetails = {},
  onClick,
  isLoading,
  ...props
}: TrackedButtonProps): ReactElement {
  const onClickInternal = (evt: MouseEvent<HTMLButtonElement>): void => {
    MixpanelActions.track(trackingName, trackingDetails)
    onClick && onClick(evt)
  }

  return (
    <LoadingButton
      {...props}
      loading={isLoading}
      onClick={onClickInternal}
      data-testid={isLoading ? "loading" : undefined}
    >
      <span>{props.children}</span>
    </LoadingButton>
  )
}
