import React, { ReactElement } from "react"
import { Link, LinkProps } from "@mui/material"

interface MailtoButtonProps extends Omit<LinkProps, "href"> {
  readonly mailTo: string | undefined
}

export function MailToLink({
  mailTo,
  ...props
}: MailtoButtonProps): ReactElement {
  return <Link {...props} href={`mailto:${mailTo}`} />
}

export function MailToSupportLink({
  children,
  ...props
}: Omit<MailtoButtonProps, "mailTo">): ReactElement {
  return (
    <MailToLink
      {...props}
      children={children ?? "support@hopeplatform.info"}
      mailTo={"support@hopeplatform.info"}
    />
  )
}
