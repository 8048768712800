import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react"
import * as therapistApi from "../api/TherapistApi"
import {
  therapistAuthenticatedRepository,
  therapistIdRepository,
} from "../repositories/therapist"

interface Auth {
  readonly isSignedIn: () => boolean
  readonly signIn: (id: string) => void
  readonly signOut: () => void
  readonly therapistId: string | null
}

interface ProvideAuthProps {
  readonly children: ReactElement
}

const AuthContext = createContext<Auth>({
  isSignedIn: () => false,
  signIn: () => {},
  signOut: () => {},
  therapistId: null,
})

export function AuthProvider({ children }: ProvideAuthProps): ReactElement {
  const [therapistId, setTherapistId] = useState(therapistIdRepository.get())

  const signIn = useCallback((id: string): void => {
    therapistIdRepository.save(id)
    setTherapistId(id)
  }, [])

  const signOut = useCallback((): void => {
    therapistIdRepository.clear()
    setTherapistId(null)
    void therapistApi.logout()
  }, [])

  const isSignedIn = useCallback((): boolean => {
    return (
      therapistAuthenticatedRepository.get() === "true" && therapistId !== null
    )
  }, [therapistId])

  const auth: Auth = {
    isSignedIn,
    signIn,
    signOut,
    therapistId,
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth(): Auth {
  return useContext(AuthContext)
}
