import { Box } from "@mui/material"
import { ReactElement } from "react"
import LogoCircle from "./hope-logo-blue-circle.png"
import LogoFull from "./hope-logo-blue-with-pink.png"
import { InternalLink } from "../../../component/links/Links"

interface LogoProps {
  readonly to: string
  readonly variant?: "full" | "circle"
}

export function Logo({ to, variant = "full" }: LogoProps): ReactElement {
  return (
    <InternalLink to={to}>
      <Box
        component={"img"}
        src={variant === "full" ? LogoFull : LogoCircle}
        alt={"Home"}
        sx={{ maxHeight: "40px" }}
      />
    </InternalLink>
  )
}
