import { ReactElement, ReactNode, useEffect } from "react"
import { Box, Container, Stack, Typography } from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import { UnexpectedErrorMessage } from "./errors/UnexpectedErrorMessage"
import { useUpdateBrowserTitle } from "./UpdateTitleHook"
import { useTrackPageLoad } from "../../hooks/AnalyticsHook"
import { Breakpoint } from "@mui/system/createTheme/createBreakpoints"

export interface PageProps {
  readonly title: string
  readonly children: ReactNode
  readonly hideTitle?: boolean
  readonly isError?: boolean
  readonly error?: unknown
  readonly maxWidth?: Breakpoint | false
  readonly center?: boolean
}

export function Page({
  title,
  children,
  hideTitle = false,
  isError = false,
  error = undefined,
  maxWidth = undefined,
  center = false,
}: PageProps): ReactElement {
  const showError = isError || !!error
  const titleCss = hideTitle
    ? visuallyHidden
    : center
    ? { textAlign: "center", marginBottom: "1rem" }
    : { marginBottom: "1rem" }

  useUpdateBrowserTitle(title)
  useTrackPageLoad(title)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [title])

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[200],
        position: "relative",
        width: "100%",
      })}
    >
      <Container
        sx={(theme) => ({
          padding: "1rem 0",
          [theme.breakpoints.down("sm")]: {
            padding: "1rem",
          },
        })}
        maxWidth={maxWidth}
      >
        <Stack alignItems={center ? "center" : "stretch"}>
          <Typography variant={"h1"} sx={titleCss}>
            {title}
          </Typography>
          {showError && <UnexpectedErrorMessage error={error} />}
          {!showError && children}
        </Stack>
      </Container>
    </Box>
  )
}
