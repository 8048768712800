import {
  Box,
  Container,
  LinkProps,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import { MailToSupportLink } from "../../component/links/MailToLink"
import React, { ReactElement } from "react"
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material"
import { ExternalLink, InternalLink } from "../../component/links/Links"

interface SocialLinkProps {
  readonly href: string
  readonly icon: ReactElement
  readonly label: string
}

function SocialLink({ href, icon, label }: SocialLinkProps): ReactElement {
  return (
    <ListItem disablePadding>
      <ExternalLink href={href} aria-label={label}>
        {icon}
      </ExternalLink>
    </ListItem>
  )
}

function FooterLink({
  to,
  children,
}: LinkProps & { to: string }): ReactElement {
  return (
    <InternalLink to={to} underline={"hover"}>
      {children}
    </InternalLink>
  )
}

export function Footer(): ReactElement {
  const styles = {
    title: {
      fontWeight: "bold",
      fontSize: "1em",
      marginBottom: "1em",
      whiteSpace: "nowrap",
    },
  }
  return (
    <Container
      component={"footer"}
      sx={{
        padding: "1rem",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: {
          xs: "1fr 1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr 1fr",
        },
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box>
        <Typography sx={styles.title}>Contact us</Typography>
        <List dense aria-label={"Contact us"}>
          <ListItem disablePadding>
            <MailToSupportLink underline={"hover"}>
              Email support
            </MailToSupportLink>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography sx={styles.title}>Social</Typography>
        <List
          dense
          aria-label={"Social"}
          sx={{
            display: "grid",
            gridTemplateColumns: "max-content max-content max-content",
            gap: "1rem",
          }}
        >
          <SocialLink
            href={"https://www.facebook.com/wearehopeplatform/"}
            icon={<Facebook />}
            label={"Facebook"}
          />
          <SocialLink
            href={"https://www.instagram.com/wearehopeplatform/"}
            icon={<Instagram />}
            label={"Instagram"}
          />
          <SocialLink
            href={"https://www.linkedin.com/company/hopeplatform/"}
            icon={<LinkedIn />}
            label={"LinkedIn"}
          />
        </List>
      </Box>
      <Box>
        <Typography sx={styles.title}>About Hope</Typography>
        <List dense aria-label={"About Hope"}>
          <ListItem disablePadding>
            <FooterLink to={"/about"}>About</FooterLink>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography sx={styles.title}>Legal</Typography>
        <List dense aria-label={"Legal"}>
          <ListItem disablePadding>
            <FooterLink to={"/privacy-policy"}>Privacy Policy</FooterLink>
          </ListItem>
          <ListItem disablePadding>
            <FooterLink to={"/terms-and-conditions"}>
              Terms & Conditions
            </FooterLink>
          </ListItem>
        </List>
      </Box>
    </Container>
  )
}
