import { ReactElement } from "react"
import { Alert, AlertTitle, Typography } from "@mui/material"
import { ApiError } from "../../../api/AxiosWrapper"
import { MailToSupportLink } from "../../../component/links/MailToLink"
import { PrimaryButton } from "../../../component/buttons/PrimaryButton"
import { useNavigate } from "react-router-dom"
import { NotFoundMessage } from "./NotFoundMessage"

interface UnexpectedErrorPageProps {
  readonly error?: unknown
}

export function UnexpectedErrorMessage({
  error,
}: UnexpectedErrorPageProps): ReactElement {
  const navigate = useNavigate()
  const isNotFoundError =
    error && error instanceof ApiError && error.status === 404

  if (isNotFoundError) {
    return <NotFoundMessage />
  }

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <Typography>
        An unexpected error happened, refresh the page to try again.
      </Typography>
      <PrimaryButton
        trackingName={"error refresh"}
        onClick={() => navigate(0)}
        sx={{ margin: "1rem 0" }}
      >
        Refresh
      </PrimaryButton>
      <Typography>
        If the problem persists then try again later or contact{" "}
        <MailToSupportLink />
      </Typography>
    </Alert>
  )
}
