import * as React from "react"
import { ReactElement, ReactNode } from "react"
import { Box } from "@mui/material"

interface MainProps {
  readonly children: ReactNode
}

export function Main({ children }: MainProps): ReactElement {
  return (
    <Box component={"main"} sx={{ display: "flex", flexGrow: "1" }}>
      {children}
    </Box>
  )
}
