import mixpanel, { Dict } from "mixpanel-browser"
import { applicationConfig } from "../../../ApplicationConfig"
import { hasAnalyticsConsentCookie } from "../cookies/CookieUtils"

if (analyticsEnabled()) {
  mixpanel.init(applicationConfig.trackingToken)
}

function analyticsEnabled() {
  return applicationConfig.trackingToken !== "" && hasAnalyticsConsentCookie()
}

export const MixpanelActions = {
  trackPageView: (pageTitle: string) => {
    if (analyticsEnabled()) {
      try {
        mixpanel.track("page-view", { pageTitle: pageTitle })
      } catch (e) {
        console.error("Failed to track page view in mixpanel", e)
      }
    }
  },
  track: (name: string, props?: Dict) => {
    if (analyticsEnabled()) {
      try {
        // WARNING: mixpanel will add data into the object here eg. token: "1234" and it will be sent to mixpanel,
        // so we need to make a copy of the object to avoid this
        mixpanel.track(name, { ...props })
      } catch (e) {
        console.error("Failed to track in mixpanel", e)
      }
    }
  },
}

export default MixpanelActions
