import { RouteObject } from "react-router-dom"

export const clientRoutes: RouteObject[] = [
  {
    path: "/therapist/:id/profile",
    lazy: () => import("./therapistProfile/PublicTherapistPage"),
  },
  {
    path: "/therapist/:id/payment",
    // @ts-ignore
    lazy: () => import("./bookingOld/BookingPaymentPage"),
  },
  {
    path: "/therapist/:id/success",
    lazy: () => import("./bookingOld/BookingSuccessPage"),
  },
  {
    path: "/search/page/:pageNumber",
    lazy: () => import("./search/SearchPage"),
  },
  {
    path: "/matching",
    lazy: () => import("./matching/MatchingFlow"),
    children: [
      {
        index: true,
        lazy: () => import("./matching/start/MatchingStartPage"),
      },
      {
        path: ":questionId",
        lazy: () => import("./matching/questions/MultiChoiceQuestionPage"),
      },
      {
        path: "results",
        lazy: () => import("./matching/results/MatchingResultsPage"),
      },
    ],
  },
]
