import { TrackedButton } from "./TrackedButton"
import { styled } from "@mui/material/styles"

export const PrimaryButton = styled(TrackedButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0.75rem 1.5rem",
  gap: "0.5em",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  borderRadius: "0.5rem",
  minWidth: "10rem",
  fontWeight: 700,
  textTransform: "none",
  ":hover": {
    backgroundColor: theme.palette.secondary.light,
  },
}))
