import { ReactElement } from "react"
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { CheckCircle, DoneOutlined } from "@mui/icons-material"
import { hopeGreen } from "../../theme"

interface TickListProps {
  items: string[]
  tickType?: "tick" | "circle"
}

export function TickList({
  items,
  tickType = "tick",
}: TickListProps): ReactElement {
  return (
    <List>
      {items.map((item) => (
        <ListItem key={item} sx={{ paddingLeft: 0 }}>
          {tickType === "tick" ? (
            <ListItemIcon>
              <DoneOutlined sx={{ color: hopeGreen[200] }} />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <CheckCircle sx={{ color: hopeGreen[200] }} />
            </ListItemIcon>
          )}
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  )
}
