import * as axiosWrapper from "./AxiosWrapper"
import { ApiErrorType, extractError, throwApiError } from "./AxiosWrapper"
import { TherapistProfile } from "../domain/therapist"
import { Discount, SessionCategory } from "./SessionApi"

interface AvailableTimeslot {
  readonly start: string
  readonly end: string
}

interface Availability {
  readonly [date: string]: ReadonlyArray<AvailableTimeslot>
}

export interface StaticData {
  readonly languages: ReadonlyArray<string>
  readonly sessionCategories: ReadonlyArray<SessionCategory>
}

export interface Tag {
  readonly id: string
  readonly description: string
}

export interface AllAvailableTags {
  readonly specialities: ReadonlyArray<Tag>
  readonly clientGroups: ReadonlyArray<Tag>
  readonly therapyTypes: ReadonlyArray<Tag>
}

export interface CommunicationPreferences {
  readonly newBookingsSms: boolean
}

export const getTherapistProfile = async (
  id: string,
  signal?: AbortSignal,
): Promise<TherapistProfile | null> =>
  axiosWrapper
    .get<TherapistProfile>(`/api/v2/therapist/${id}/profile`, { signal })
    .catch(throwApiError)

export const getNextAvailableTimeslot = async (
  therapistId: string,
  sessionId: number,
  signal?: AbortSignal,
): Promise<string> =>
  axiosWrapper
    .get<string>(
      `/therapist/${therapistId}/${sessionId}/next-available-timeslot`,
      { signal },
    )
    .catch(throwApiError)

/** @deprecated */
export const getDiscountedCost = async (
  id: string,
  sessionId: string,
  code: string,
): Promise<Discount | ApiErrorType> =>
  axiosWrapper
    .get<Discount>(
      `/therapist/${id}/session/${sessionId}/discount?code=${code}`,
    )
    .catch(extractError)

/** @deprecated */
export const getAvailability = async (
  id: string,
  sessionDuration: number,
  dateFrom: string,
  timezone: string,
  sessionId: string,
): Promise<Availability | ApiErrorType> => {
  const params = `sessionDuration=${sessionDuration}&dateFrom=${dateFrom}&timezone=${timezone}&sessionId=${sessionId}`
  return axiosWrapper
    .get<Availability>(`/therapist/${id}/availability?${params}`)
    .catch(extractError)
}

/** @deprecated */
export const getRepeatSessionAvailability = async (
  id: string,
  sessionDuration: unknown,
  dateFrom: unknown,
  dateTo: unknown,
  timezone: unknown,
  sessionId: string,
): Promise<Availability | ApiErrorType> => {
  const params = `sessionDuration=${sessionDuration}&dateFrom=${dateFrom}&dateTo=${dateTo}&timezone=${timezone}&sessionId=${sessionId}`
  return axiosWrapper
    .get<Availability>(`/therapist/${id}/repeat-availability?${params}`)
    .catch(extractError)
}

export const getStaticData = async (
  signal?: AbortSignal,
): Promise<StaticData> =>
  axiosWrapper.get<StaticData>("/data", { signal }).catch(throwApiError)

export async function getAllAvailableTags(
  signal?: AbortSignal,
): Promise<AllAvailableTags> {
  return axiosWrapper
    .get<AllAvailableTags>("/api/v1/tags", { signal })
    .catch(throwApiError)
}

export const updateProfile = async (
  id: string,
  updatedProfile: TherapistProfile,
  signal?: AbortSignal,
): Promise<TherapistProfile> =>
  axiosWrapper
    .put<TherapistProfile>(`/api/v2/therapist/${id}/profile`, updatedProfile, {
      signal,
    })
    .catch(throwApiError)

export const updateProfileImage = async (
  id: string,
  canvas: HTMLCanvasElement,
): Promise<string> => {
  const formData = new FormData()
  const additionalConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const blob = await new Promise<Blob>((resolve, reject) =>
    canvas.toBlob(
      (b) => {
        b ? resolve(b) : reject()
      },
      "image/png",
      1,
    ),
  )
  const imageFile = new File([blob], `${id}.png`)
  formData.append("image", imageFile, imageFile.name)
  return axiosWrapper
    .post<string>(`/therapist/${id}/profile-image`, formData, additionalConfig)
    .catch(throwApiError)
}

export const connectStripe = (
  id: string,
  signal?: AbortSignal,
): Promise<string> =>
  axiosWrapper
    .get<string>(`/therapist/${id}/stripe/connect`, { signal })
    .catch(throwApiError)

export const updateStripe = (id: string): Promise<void> =>
  axiosWrapper.put<void>(`/therapist/${id}/stripe/update`).catch(throwApiError)

export const getStripeDashboardLink = (
  id: string,
  signal?: AbortSignal,
): Promise<string> =>
  axiosWrapper
    .get<string>(`/therapist/${id}/stripe/dashboard`, { signal })
    .catch(throwApiError)

export const updateTermsAndConditionsAndPrivacyPolicyAcceptance = (
  id: string,
  termsAndConditionsAccepted: Date,
  privacyPolicyAccepted: Date,
): Promise<void> =>
  axiosWrapper
    .put<void>(
      `/api/v1/therapist/${id}/accept-terms-and-conditions-and-privacy-policy`,
      { termsAndConditionsAccepted, privacyPolicyAccepted },
    )
    .catch(throwApiError)

export const getTsAndCsAndPrivacyPolicyAcceptedStatus = (
  id: string,
  signal?: AbortSignal,
): Promise<boolean> =>
  axiosWrapper
    .get<boolean>(`/api/v1/therapist/${id}/terms-accepted`, { signal })
    .catch(throwApiError)

export const hasCalendarPermissions = (
  id: string,
  signal?: AbortSignal,
): Promise<boolean> =>
  axiosWrapper
    .get<boolean>(`/api/v1/therapist/${id}/calendar/has-permissions`, {
      signal,
    })
    .catch(throwApiError)

export const logout = (): Promise<void> =>
  axiosWrapper.post<void>(`/user-logout`, {})

export const getCommunicationPreferences = async (
  id: string,
  signal?: AbortSignal,
): Promise<CommunicationPreferences> =>
  axiosWrapper
    .get<CommunicationPreferences>(
      `/api/v1/therapist/${id}/communication-preferences`,
      { signal },
    )
    .catch(throwApiError)

export const updateCommunicationPreferences = async (
  id: string,
  updated: CommunicationPreferences,
  signal?: AbortSignal,
): Promise<CommunicationPreferences> =>
  axiosWrapper
    .put<CommunicationPreferences>(
      `/api/v1/therapist/${id}/communication-preferences`,
      updated,
      {
        signal,
      },
    )
    .catch(throwApiError)
