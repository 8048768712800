import { styled } from "@mui/material"
import { SecondaryButton } from "./SecondaryButton"
import { TrackedButtonProps } from "./TrackedButton"
import { OpenInNewOutlined } from "@mui/icons-material"
import { ExternalLink, InternalLink } from "../links/Links"
import { PrimaryButton } from "./PrimaryButton"

export const LinkButton = styled((props: TrackedButtonProps) => {
  return <SecondaryButton {...props} component={InternalLink} />
})(() => ({
  textDecoration: "none",
}))

export const LinkPrimaryButton = styled((props: TrackedButtonProps) => {
  return <PrimaryButton {...props} component={InternalLink} />
})(() => ({
  textDecoration: "none",
}))

export const ExternalLinkButton = styled(
  ({
    openInNewTab,
    ...props
  }: TrackedButtonProps & { openInNewTab?: boolean }) => {
    return (
      <SecondaryButton
        {...props}
        component={ExternalLink}
        openInNewTab={openInNewTab}
        endIcon={openInNewTab ? <OpenInNewOutlined /> : undefined}
      />
    )
  },
)(() => ({
  textDecoration: "none",
}))
