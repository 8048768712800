import { ReactElement, ReactNode, useEffect } from "react"
import { useUpdateBrowserTitle } from "../common/UpdateTitleHook"
import { Box, Container, Typography } from "@mui/material"
import { useTrackPageLoad } from "../../hooks/AnalyticsHook"

interface MarketingPageProps {
  readonly title: string
  readonly hero?: ReactNode
  readonly left?: ReactNode
  readonly right?: ReactNode
  readonly children?: ReactNode
}

export function MarketingPage({
  title,
  hero,
  left,
  right,
  children,
}: MarketingPageProps): ReactElement {
  useUpdateBrowserTitle(title)
  useTrackPageLoad(title)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [title])

  const columns = !!left && !!right ? "1fr 1fr" : "1fr"

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        width: "100%",
      })}
    >
      {hero && <Box sx={{ marginBottom: "4rem", width: "100%" }}>{hero}</Box>}
      <Container sx={{ marginTop: "1rem" }}>
        <Box
          sx={{
            display: "grid",
            gap: "2rem",
            gridTemplateColumns: { xs: "1fr", md: columns },
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Typography variant={"h1"}>{title}</Typography>
            {left}
          </Box>
          <Box sx={{ display: "grid" }}>{right}</Box>
        </Box>
      </Container>
      <Container sx={{ marginBottom: "1rem" }}>{children}</Container>
    </Box>
  )
}
