import { RouteObject } from "react-router-dom"

export const therapistRoutes: RouteObject[] = [
  {
    path: "my",
    lazy: () => import("./AuthenticatedOnly"),
    children: [
      {
        lazy: () => import("./NoDashboardLayout"),
        children: [
          {
            path: "calendar-permissions",
            lazy: () =>
              import(
                "./authentication/signup/CalendarPermissionsNotAcceptedPage"
              ),
          },
          {
            path: "terms-acceptance",
            lazy: () =>
              import(
                "./authentication/signup/TermsAndConditionsAcceptancePage"
              ),
          },
          {
            path: "signup",
            lazy: () => import("./authentication/signup/CreateProfilePage"),
          },
          {
            path: "stripe/return",
            lazy: () => import("./stripe/StripeReturnPage"),
          },
          {
            path: "preview/:id",
            lazy: () => import("./dashboard/publicProfile/PreviewPage"),
          },
          {
            path: "sessions/:sessionId",
            lazy: () => import("./session/SessionFlow"),
            children: [
              {
                path: "details",
                lazy: () => import("./session/details/SessionDetailsPage"),
              },
              {
                path: "location",
                lazy: () => import("./session/location/SessionLocationPage"),
              },
              {
                path: "conferencing",
                lazy: () =>
                  import("./session/conferencing/ConferenceProviderPage"),
              },
              {
                path: "fees",
                lazy: () => import("./session/fees/SessionFeesPage"),
              },
              {
                path: "working-hours",
                lazy: () =>
                  import("./session/workingHours/SessionWorkingHoursPage"),
              },
              {
                path: "fees",
                lazy: () => import("./session/fees/SessionFeesPage"),
              },
            ],
          },
          {
            path: "bookings/:bookingId/cancel",
            lazy: () => import("./booking/CancelBookingPage"),
          },
        ],
      },
      {
        path: "dashboard",
        lazy: () => import("./dashboard/DashboardLayout"),
        children: [
          {
            index: true,
            lazy: () => import("./dashboard/home/DashboardHomePage"),
          },
          {
            path: "profile",
            lazy: () => import("./dashboard/yourDetails/YourDetailsPage"),
            children: [
              {
                path: "edit-name",
                lazy: () =>
                  import("./dashboard/yourDetails/name/EditNameModal"),
              },
              {
                path: "edit-photo",
                lazy: () =>
                  import("./dashboard/yourDetails/photo/EditPhotoModal"),
              },
              {
                path: "edit-contact",
                lazy: () =>
                  import("./dashboard/yourDetails/contact/EditContactModal"),
              },
              {
                path: "edit-languages",
                lazy: () =>
                  import(
                    "./dashboard/yourDetails/languages/EditLanguagesModal"
                  ),
              },
            ],
          },
          {
            path: "professional",
            lazy: () =>
              import("./dashboard/professionalDetails/ProfessionalDetailsPage"),
            children: [
              {
                path: "edit-membership",
                lazy: () =>
                  import(
                    "./dashboard/professionalDetails/membership/EditMembershipModal"
                  ),
              },
              {
                path: "edit-statement",
                lazy: () =>
                  import(
                    "./dashboard/professionalDetails/statement/EditProfessionalStatementModal"
                  ),
              },
              {
                path: "edit-categories",
                lazy: () =>
                  import(
                    "./dashboard/professionalDetails/categories/EditProfessionalCategoriesModal"
                  ),
              },
              {
                path: "edit-location",
                lazy: () =>
                  import(
                    "./dashboard/professionalDetails/location/EditLocationModal"
                  ),
              },
            ],
          },
          {
            path: "sessions",
            lazy: () => import("./dashboard/sessions/SessionsPage"),
            children: [
              {
                path: "sessions/:sessionId/delete",
                lazy: () => import("./dashboard/sessions/DeleteSessionModal"),
              },
            ],
          },
          {
            path: "payments",
            lazy: () => import("./dashboard/payments/PaymentsPage"),
          },
          {
            path: "bookings/page/:pageNumber",
            lazy: () => import("./dashboard/bookings/BookingsPage"),
          },
          {
            path: "bookings/:bookingId",
            lazy: () =>
              import("./dashboard/bookings/booking/BookingDetailsPage"),
            children: [
              {
                path: "join-meeting",
                lazy: () =>
                  import("./dashboard/bookings/booking/JoinMeetingModal"),
              },
            ],
          },
          {
            path: "public",
            lazy: () => import("./dashboard/publicProfile/PublicProfilePage"),
          },
          {
            path: "communication-preferences",
            lazy: () =>
              import(
                "./dashboard/communicationPreferences/CommunicationPreferencesPage"
              ),
            children: [
              {
                path: "edit-new-bookings",
                lazy: () =>
                  import(
                    "./dashboard/communicationPreferences/newBookings/EditNewBookingsModal"
                  ),
              },
              {
                path: "edit-upcoming-bookings",
                lazy: () =>
                  import(
                    "./dashboard/communicationPreferences/upcomingBookings/EditUpcomingBookingsModal"
                  ),
              },
              {
                path: "edit-cancelled-bookings",
                lazy: () =>
                  import(
                    "./dashboard/communicationPreferences/cancelledBookings/EditCancelledBookingsModal"
                  ),
              },
              {
                path: "edit-important-changes",
                lazy: () =>
                  import(
                    "./dashboard/communicationPreferences/importantChanges/EditImportantChangesModal"
                  ),
              },
            ],
          },
          {
            path: "help",
            lazy: () => import("./dashboard/help/HelpPage"),
          },
        ],
      },
    ],
  },
]
