import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import {
  CelebrationOutlined,
  HelpOutlineOutlined,
  LoginOutlined,
  LogoutOutlined,
  SpaceDashboardOutlined,
  SchoolOutlined,
} from "@mui/icons-material"
import { ReactElement } from "react"
import { NavLink } from "react-router-dom"
import { useAuth } from "../../../hooks/UseAuth"

interface TherapistMenuProps {
  readonly anchorEl: HTMLElement | null
  readonly setAnchorEl: (achorEl: HTMLElement | null) => void
}

export function TherapistMenuButton({
  anchorEl,
  setAnchorEl,
}: TherapistMenuProps): ReactElement {
  return (
    <Button
      id={"therapist-menu"}
      aria-controls={anchorEl ? "therapist-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={anchorEl ? "true" : undefined}
      onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
    >
      For therapists
    </Button>
  )
}

export function TherapistMenu({
  anchorEl,
  setAnchorEl,
}: TherapistMenuProps): ReactElement {
  const { isSignedIn, signOut } = useAuth()

  const onClose = () => setAnchorEl(null)

  return (
    <Menu
      id={"therapist-menu"}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "therapist-menu",
      }}
    >
      <MenuItem component={NavLink} to={"/therapists"} onClick={onClose}>
        <ListItemIcon>
          <HelpOutlineOutlined />
        </ListItemIcon>
        <ListItemText>Why Hope?</ListItemText>
      </MenuItem>
      {!isSignedIn() && [
        // MUI gives a warning if we try and use a Fragment <></>
        // Using an array forces us to add "key" otherwise we get another warning
        <MenuItem
          key={"1"}
          component={NavLink}
          to={"/signup"}
          onClick={onClose}
        >
          <ListItemIcon>
            <CelebrationOutlined />
          </ListItemIcon>
          <ListItemText>Get started for free</ListItemText>
        </MenuItem>,
        <MenuItem key={"2"} component={NavLink} to={"/login"} onClick={onClose}>
          <ListItemIcon>
            <LoginOutlined />
          </ListItemIcon>
          <ListItemText>Sign in</ListItemText>
        </MenuItem>,
        <Divider key={"3"} />,
        <MenuItem
          key={"4"}
          component={NavLink}
          to={"/students"}
          onClick={onClose}
        >
          <ListItemIcon>
            <SchoolOutlined />
          </ListItemIcon>
          <ListItemText>Students</ListItemText>
        </MenuItem>,
      ]}
      {isSignedIn() && [
        <Divider key={"1"} />,
        <MenuItem
          key={"2"}
          component={NavLink}
          to={"/my/dashboard"}
          onClick={onClose}
        >
          <ListItemIcon>
            <SpaceDashboardOutlined />
          </ListItemIcon>
          <ListItemText>My dashboard</ListItemText>
        </MenuItem>,
        <MenuItem key={"3"} onClick={() => signOut()}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>,
      ]}
    </Menu>
  )
}
