/*
 * This relies on the fact we currently build different bundles for staging and
 * prod on CI.
 */

interface Toggles {
  readonly filterOnSessionCategoriesHop273: boolean
  readonly matchingFlowHop285: boolean
  readonly updateMatchingHop295: boolean
}

export const developmentToggles: Toggles = {
  filterOnSessionCategoriesHop273: true,
  matchingFlowHop285: true,
  updateMatchingHop295: true
}

export const stagingToggles: Toggles = {
  filterOnSessionCategoriesHop273: true,
  matchingFlowHop285: true,
  updateMatchingHop295: true
}

export const productionToggles: Toggles = {
  filterOnSessionCategoriesHop273: true,
  matchingFlowHop285: false,
  updateMatchingHop295: false
}

export function isFilterOnSessionCategoriesEnabled(): boolean {
  return isToggleEnabled("filterOnSessionCategoriesHop273")
}

export function isMatchingFlowEnabled(): boolean {
  return isToggleEnabled("matchingFlowHop285")
}

export function isUpdateMatchingEnabled(): boolean {
  return isToggleEnabled("updateMatchingHop295")
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isToggleEnabled(toggle: keyof Toggles): boolean {
  if (process.env.REACT_APP_TOGGLE_ENV === "development") {
    return developmentToggles[toggle]
  } else if (process.env.REACT_APP_TOGGLE_ENV === "staging") {
    return stagingToggles[toggle]
  } else {
    return productionToggles[toggle]
  }
}
