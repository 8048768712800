import * as React from "react"
import { ReactElement } from "react"
import { Outlet, useNavigation } from "react-router-dom"
import { AuthProvider } from "./hooks/UseAuth"
import {
  Backdrop,
  Box,
  CssBaseline,
  LinearProgress,
  ThemeProvider,
} from "@mui/material"
import { SnackbarProvider } from "notistack"
import { rootTheme } from "./theme"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import CookiesBanner from "./pages/common/cookies/CookiesBanner"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export function AppRoot(): ReactElement {
  const { state } = useNavigation()

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={rootTheme}>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <>
              <CssBaseline />
              <LinearProgress
                color={"secondary"}
                sx={{
                  display: state === "loading" ? "block" : "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: (theme) => theme.zIndex.modal + 2,
                  width: "100%",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <Backdrop
                  open={state === "loading"}
                  sx={{
                    cursor: "wait",
                    zIndex: (theme) => theme.zIndex.modal + 1,
                  }}
                />
                <Outlet />
              </Box>
              <CookiesBanner />
            </>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
