import { ReactElement } from "react"
import { Typography } from "@mui/material"
import { MailToSupportLink } from "../../../component/links/MailToLink"

export function NotFoundMessage(): ReactElement {
  return (
    <>
      <Typography>
        Whoops it looks like the page you're looking for can't be found.
      </Typography>
      <Typography>
        If you received this link from your Therapist, please ask them for a new
        link or contact support using <MailToSupportLink />
      </Typography>
    </>
  )
}
